body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#cursorBlob {
  width: 50px;
  height: 50px;
  background: linear-gradient(120deg, orange, tomato, orangered, transparent, #76FF03);
  background-size: 1600% 1600%;
  position: absolute;
  mix-blend-mode: difference;
  pointer-events: none;
  z-index: 1000000;
  -webkit-transition: 0.15s linear;
  transition: 0.15s linear;
  -webkit-animation: blobRadius 5s ease infinite, blobBackground 15s ease infinite;
          animation: blobRadius 5s ease infinite, blobBackground 15s ease infinite;
}

@-webkit-keyframes blobRadius {
  0%, 100% {
    border-radius: 43% 77% 80% 40% / 40% 40% 80% 80%;
  }
  20% {
    border-radius: 47% 73% 61% 59% / 47% 75% 45% 73%;
  }
  40% {
    border-radius: 46% 74% 74% 46% / 74% 58% 62% 46%;
  }
  60% {
    border-radius: 47% 73% 61% 59% / 40% 40% 80% 80%;
  }
  80% {
    border-radius: 50% 70% 52% 68% / 51% 61% 59% 69%;
  }
}

@keyframes blobRadius {
  0%, 100% {
    border-radius: 43% 77% 80% 40% / 40% 40% 80% 80%;
  }
  20% {
    border-radius: 47% 73% 61% 59% / 47% 75% 45% 73%;
  }
  40% {
    border-radius: 46% 74% 74% 46% / 74% 58% 62% 46%;
  }
  60% {
    border-radius: 47% 73% 61% 59% / 40% 40% 80% 80%;
  }
  80% {
    border-radius: 50% 70% 52% 68% / 51% 61% 59% 69%;
  }
}

@-webkit-keyframes blobBackground {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

@keyframes blobBackground {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
html {
  scroll-behavior: smooth;

}